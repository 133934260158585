<template>
  <label class="switch">
    <input type="checkbox" @change="triggerToggle($event)" :checked="value" />
    <span class="slider round"></span>
  </label>
</template>

<script setup>
const emit = defineEmits(["update:modelValue"]);
// Props
const props = defineProps({
  value: [String, Number, Boolean],
});
// Methods
const triggerToggle = (e) => {
  emit("update:modelValue", e.target.checked);
  emit("checked", e.target.checked);
  // emit("onChange", e.target.checked);
};
</script>
